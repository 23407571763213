
import 'modaal';
import 'intersection-observer';
import { throttle } from "lodash";
import Cookies from 'js-cookie'

document.addEventListener('DOMContentLoaded', function(){

	// TODO: ページトップへ戻る
	/*
	$('#js-scroll-to-top').on('click', function () {
		$('html,body').animate({
			'scrollTop': 0
		}, 500);
	});
	*/
	var pagetop = $('.backtop');
	var h = $(".l-footer").offset();
	var h3 = $(window).outerHeight();
	pagetop.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			pagetop.fadeIn();
		} else {
			pagetop.fadeOut();
		}
		if ($(this).scrollTop() > h.top - h3) {
			pagetop.addClass("bottom");
		}
		if ($(this).scrollTop() <= h.top - h3) {
			pagetop.removeClass("bottom");
		}
	});
	pagetop.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
		return false;
	});

	// TODO: ドロワーを開く
	$('#js-header__button--open').on('click', function () {
		$('#js-drawer__overlay').toggleClass('is-active');
		$('#js-drawer__button--close').toggleClass('is-active');
		$('#js-drawer__body').toggleClass('is-active');
	});

	// TODO: ドロワーを閉じる
	$('#js-drawer__overlay, #js-drawer__button--close').on('click', function () {
		$('#js-drawer__overlay').removeClass('is-active');
		$('#js-drawer__button--close').removeClass('is-active');
		$('#js-drawer__body').removeClass('is-active');
	});

	// TODO: ドロワー内
	$(".p-drawer-menu li.has-sub .icon").click(function(){
		$("body").addClass("fix");
		$(".p-drawer-menu li .sub-menu").not($($(this).parent().find(".sub-menu"))).removeClass("on");
		$(this).parent().find(".sub-menu").addClass("on");
		$(".l-drawer__contents").addClass("on");
	});
	$(".p-drawer-menu li .sub-menu .back").click(function(){
		$("body").removeClass("fix");
		$(this).closest(".has-sub").find(".sub-menu").removeClass("on");
		$(".l-drawer__contents").removeClass("on");
	});
	$(".has_sub01").click(function (e) {
		$(".l-drawer__body,.l-drawer__overlay").addClass("is-active");
		e.preventDefault();
		$("body").addClass("fix");
		$(".has-sub--cat").find(".sub-menu").addClass("on");
		$(".has-sub--brand").find(".sub-menu").removeClass("on");
		$(".l-drawer__contents").addClass("on");
	});
	$(".has_sub02").click(function (e) {
		$(".l-drawer__body,.l-drawer__overlay").addClass("is-active");
		e.preventDefault();
		$("body").addClass("fix");
		$(".has-sub--brand").find(".sub-menu").addClass("on");
		$(".has-sub--cat").find(".sub-menu").removeClass("on");
		$(".l-drawer__contents").addClass("on");
	});

	// TODO: カートドロワーの開閉
	/*
	$('#js-header__utility-cart').on('click', '#js-cart-trigger, #js-cart-cancel, #js-cart__overlay', function () {
		$('.js-cart-target').toggleClass('is-active');
	});
	*/

	/*
	// TODO: ドロワー内のカテゴリを開閉
	$('.js-category__heading').on('click', function () {
		$(this).siblings('.js-category__list').slideToggle(200, 'linear');
		$(this).toggleClass('is-active');
	});
	*/

	// TODO: イベント実行時のオーバーレイ処理
	// classに「load-overlay」が記述されていると画面がオーバーレイされる
	$('.js-load-overlay').on({
		click: function () {
			loadingOverlay();
		},
		change: function () {
			loadingOverlay();
		}
	});

	// TODO: modaalの呼び出し
	// ヘッダーの検索アイコンクリック時にモーダルが表示される
	/*
	$('#js-header__utility-search-link').modaal({
		custom_class: 'c-modaal',
		overlay_opacity: 0.5
	});
	*/

	// TODO: IntersectionObserverでの監視
	/*
	const observerTargets = Array.prototype.slice.call(document.querySelectorAll('#js-top-app-bar, #js-top__main-visual, .js-animation-image, #js-recommend__contents'), 0);
	const observerOptions = [{
		root: null,
		rootMargin: "0px",
		threshold: 1.0
	},{
		root: null,
		rootMargin: "0px",
		threshold: [0, 1.0]
	},{
		root: null,
		rootMargin: "0px",
		threshold: 0.25
	}, {
		root: null,
		rootMargin: "0px",
		threshold: 0.5
	}];
	const topAppBarObserver = new IntersectionObserver(intersectState, observerOptions[0]);
	const mainVisualObserver = new IntersectionObserver(intersectTransparent, observerOptions[1]);
	const imageObserver = new IntersectionObserver(intersectShow, observerOptions[2]);
	const recommendObserver = new IntersectionObserver(intersectShow, observerOptions[3]);
	observerTargets.forEach(function(elem) {
		if(elem.getAttribute('id') == 'js-top-app-bar'){
			topAppBarObserver.observe(elem);
		} else if(elem.getAttribute('id') == 'js-top__main-visual'){
			mainVisualObserver.observe(elem);
		} else if(elem.classList.contains('js-animation-image') == true) {
			imageObserver.observe(elem);
		} else {
			recommendObserver.observe(elem);
		}
	});
	*/

	// TODO: 3秒後にstopLoading呼び出し
	setTimeout(stopLoading, 3000);

	// TODO: SP表示の際、footerのspecial iconのマークを表示非表示
	var date = new Date();
	var cookieEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
	if(Cookies.get("icon_key") == undefined) {
		$('#js-footer-menu__img-mark').show();
	} else {
		$('#js-footer-menu__img-mark').hide();
	}
	$('#js-cookie-icon').on('click', function() {
		if(Cookies.get("icon_key") == undefined) {
			Cookies.set("icon_key", "show", {
				expires: cookieEnd,
				path: '/'
			});
		}
	});

},false);

window.addEventListener('load', function(){

	// TODO: リソース読み込み完了時にクラス付与
	//$('#js-loading, #js-animation-cross').addClass('is-loaded');
	$('#js-loading').addClass('is-loaded');

	scrollToTopFloating();
	//moveElem();

},false);

const FPS = 1000 / 60;
window.addEventListener('scroll', throttle(function(){
	scrollToTopFloating();
}, FPS), isPassiveSupport() ? {
	passive: true
}: false);

/*
let resizeTimer = false;
window.addEventListener("resize", function(){
	if(resizeTimer){
		clearTimeout(resizeTimer);
	}
	resizeTimer = setTimeout(function(){
		moveElem();
		resizeTimer = false;
	}, 200);
}, isPassiveSupport() ? {
	passive: true
}: false);
*/

window.addEventListener("pageshow", function(){
	loadingOverlay('hide');
}, false);

/**
 *  オーバーレイ処理
 */
let loadingOverlay = function(action) {
	let $overlay;
	if (action == 'hide') {
		$('#js-overlay').remove();
	} else {
		$overlay = $('<div class="c-load-overlay" id="js-overlay"></div>');
		$('body').append($overlay);
	}
}
window.loadingOverlay = loadingOverlay;

/**
 *  TOP以外の表示時にTOP入り口非表示トリガーのsessionStorageを付与
 */

if(location.pathname != '/'){
	sessionStorage.setItem('access', 0);
}





/**
 *  top-app-barの状態
 */
/*
function intersectState(entry) {
	if (entry[0].isIntersecting) {
		entry[0].target.classList.remove('is-hide');
		entry[0].target.classList.add('is-show');
	} else {
		entry[0].target.classList.remove('is-show');
		entry[0].target.classList.add('is-hide');
		let categoryElem = document.getElementById('js-header__category');
		if(categoryElem.classList.contains('mdc-menu-surface--open') == true) {
			window.menuElements[0].open = false;
		}
	}
}
*/

/**
 *  headerの透過
 */
/*
function intersectTransparent(entry) {
	let headerElem = document.getElementById('js-top-app-bar');
	if (entry[0].isIntersecting) {
		headerElem.classList.add('is-transparent');
	} else {
		headerElem.classList.remove('is-transparent');
	}
}
*/

/**
 *  画像の表示アニメーション
 */
/*
function intersectShow(entries) {
	const entriesArray = Array.prototype.slice.call(entries, 0);
	entriesArray.forEach(function(entry) {
		if (entry.isIntersecting) {
			entry.target.classList.add("is-show");
		}
	});
}
*/

/**
 *  headerの配置要素替え
 */
/*
function moveElem() {
	let headerCat = $('#js-header__category'),
		drawer = $('#js-drawer__contents'),
		category = $('#js-category'),
		menu = $('#js-login');
	if (window.matchMedia('(max-width: 839px)').matches) {
		if(!drawer.has(category)[0]) {
			category.appendTo(drawer);
			menu.appendTo(drawer);
		}
	} else {
		if(!headerCat.has(category)[0]) {
			category.prependTo(headerCat);
			menu.prependTo('#js-header__utility');
		}
	}
}
*/

/**
 *  scroll to top
 */

function scrollToTopFloating() {
	/*
	let target = $('#js-scroll-to-top');
	if ($(window).scrollTop() > 500) {
		target.addClass('is-show');
		target.removeClass('is-hide');
	} else {
		target.addClass('is-hide');
		target.removeClass('is-show');
	}
	*/
	if ($(window).scrollTop() > 100) {
		$(".l-header").addClass("on");
	} else {
		$(".l-header").removeClass("on");
	}
}


/**
 *  loadingが完了しない場合
 */
function stopLoading(){
	$('#js-loading').addClass('is-loaded');
}

/**
 *  Passive Event Listeners polyfill
 */

function isPassiveSupport(){
  if (typeof window === 'object' && typeof window.addEventListener === 'function' && Object.defineProperty) {
	let passive = false;
	const options = Object.defineProperty({}, 'passive', {
		get() {
			passive = true;
			return passive;
		}
	});
	window.addEventListener('test', null, options);
	return passive;
  }
}
